.App {
  text-align: center;
}
.travelSafety-body {
  letter-spacing: -0.5px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.general-title {
  width: auto;
  font-size: 24px;
  align-items: center;
  color: #393939;
  justify-content: center;
  display: flex;
  font-weight: 600;
  letter-spacing: unset;
}
.spam-15 {
  height: 15px;
  display: block;
}
.spam-20 {
  height: 20px;
  display: block;
}
.spam-40 {
  height: 40px;
  display: block;
}

.text-bold-16 {
  font-size: 16px;
  font-weight: bold;
  color: #393939;
  line-height: 20px;
}
.text-regular-16 {
  font-size: 16px;
  color: #393939;
  line-height: 20px;
}

.text-bold-21 {
  font-size: 21px;
  color: #393939;
  font-weight: bold;
  text-decoration: none;
}
.central-text {
  margin: 0px 20px 0px 20px;
  text-align: center;
}
.logo {
  width: 100px;
  height: 100px;
}
.home-main-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-text-container {
  width: auto;
  margin-top: 8px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  color: #393939;
  flex-direction: column;
  display: flex;
  display: flex;
}
.bg-watermark-image {
  width: 55%;
  display: flex;
  position: absolute;
  right: 0;
  z-index: -10;
}
