.section {
  display: flex;
  flex-direction: column;
  width: auto;
}

.sectionTitle-main {
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
}

.sectionTitle-image {
  width: 40px;
  height: 40px;
}
.sectionTitle-text {
  color: #f3681a;
  margin-left: 11.5px;
  font-size: 18px;
}

.sectionText-main {
  width: auto;
  margin-top: 15px;
  line-height: 20px;
  align-items: stretch;
  font-size: 16px;
  color: #393939;
}

.section-readmore-text {
  font-size: 16px;
  font-weight: bold;
  color: #393939;
  line-height: 20px;
  text-decoration: none;
}