.nav-tab-main {
  width: auto;
  display: flex;
  flex-direction: row;
}
.nav-tab-container {
  margin-right: 24px;
  cursor: pointer;
  background-color: transparent !important;
  align-items: center;
  display: flex;
  flex-direction: column;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}
.nav-tab-selected-indicator {
  width: 19px;
  height: 4px;
  border-radius: 12px;
  background-color: #393939;
}
.nav-tab-text-inactive {
  color: #e1ddd9;
  font-size: 21px;
  font-weight: bold;
  text-decoration: none;
}
